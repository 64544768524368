<template>
  <router-layout>
    <div class="page">
      <div class="inner section1">
        <van-swipe :autoplay="3000" class="swiper">
          <van-swipe-item v-for="(item, index) in swiper" :key="index">
            <img :src="item.image" />
          </van-swipe-item>
        </van-swipe>
        <div class="menu">
          <ul>
            <li @click="$router.push('/Index')">
              <div>
                <img src="@/assets/images/icon1.png" />
              </div>
              <p>国资租赁</p>
            </li>
            <li @click="toHouse">
              <div>
                <img src="@/assets/images/icon2.png" />
              </div>
              <p>房屋租售</p>
            </li>
            <li @click="toRecruitmentList">
              <div>
                <img src="@/assets/images/icon3.png" />
              </div>
              <p>人才招聘</p>
            </li>
            <li @click="toFindJobList">
              <div>
                <img src="@/assets/images/icon4.png" />
              </div>
              <p>求职简历</p>
            </li>
            <li @click="toPromote">
              <div>
                <img src="@/assets/images/icon5.png" />
              </div>
              <p>推广产品</p>
            </li>
            <li @click="toPark">
              <div>
                <img src="@/assets/images/icon6.png" />
              </div>
              <p>停车缴费</p>
            </li>
            <li @click="showPop">
              <div>
                <img src="@/assets/images/icon7.png" />
              </div>
              <p>使用须知</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="line"></div>
      <div class="inner section2">
        <div class="top">
          <div class="t">热点资讯</div>
          <div class="more" @click="toNewsList">更多</div>
        </div>
      </div>
      <div class="inner section3">
        <van-loading
          color="#1989fa"
          type="spinner"
          class="dataLoading"
          v-if="$store.state.dataLoading"
        />
        <div v-else>
          <ul v-if="hasData">
            <li v-for="(item,index) in List" :key="index" @click="toNewsDetail(item)">
              <div class="img" :style="{ 'background': 'url(' + item.image + ')'}"></div>
              <div class="txt">
                <div>
                  <h2>{{item.title}}</h2>
                </div>
                <div class="sumary">
                  <!-- <span>{{item.read}}阅读</span> -->
                  <span>{{item.time}}</span>
                </div>
              </div>
            </li>
          </ul>
          <van-empty class="emptxt" description="暂无内容" v-else />
        </div>
      </div>
      <tabBar ref="tabbar" :tabbar-active="tabbarActive" />
      <!-- 使用须知 -->
      <van-popup v-model="show" class="agreePop" closeable close-icon="close" @close="closePop">
        <div class="tit">使用须知</div>
        <div class="body">
          <p>通过简单地注册之后，您可以选择发布房屋租售、求职招聘、生活服务、推广产品等各类日常生活信息。发布的信息在管理员审核通过之后，方可在平台上展示。</p>
          <h2>免责声明</h2>
          <p>1、禁止发布违反法律法规的内容，一经发现马上删除，根据情节将上报相关部门。</p>
          <p>2、禁止发布微商，招代理，刷单，代办信用卡，网络兼职，投资，二维码等信息，如有发现马上删除并不退款。</p>
          <p>
            3、在本系统发布的信息将整理后进行发布（不保证所有都发布）。
            <br />
          </p>
          <p>4、在本系统发布的信息有可能会被其它网站复制或搜索引擎收录，如要删除请自行联系相应网站。</p>
          <p>
            5、为提高效果及用户体验请把内容发布到相应分类，如发错分类发现后我们会移动到相应分类。
            <br />
          </p>
          <p>6、以上声明会根据运营情况进行修改。</p>
          <p>7、所有发布的信息都默认同意以上声明。</p>
          <div class="foot"></div>
        </div>
      </van-popup>
    </div>
  </router-layout>
</template>
<script>
import tabBar from "@/components/tabbar/tabBar";
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      tabbarActive: 0,
      show: false,
      home: true,
      hasData: true,
      page: 0,
      swiper: [
        { image: require("@/assets/images/slider1.jpg"), url: "" },
        { image: require("@/assets/images/slider2.png"), url: "" }
      ],
      List: [] //新闻
    };
  },
  mixins: [authMixin],
  components: {
    tabBar
  },
  created() {
    this.$store.commit("setDataLoading", true);
    let token = this.$storage.get("token");
    if (token) {
      this.getNews();
    } else {
      this.WxAuth();
    }
  },
  methods: {
    async WxAuth() {
      //第一次进入授权
      try {
        let code = this.$route.query.code;
        let token = this.$storage.get("token");
        if (token) {
        } else {
          if (code) {
            await this.$authRequest({
              method: "get",
              url: "/getWebToken",
              params: {
                code: code
              }
            }).then(res => {
              this.$storage.set("token", res.token.token);
            });
            this.getNews();
          } else {
            this.auth();
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    auth() {
      window.location.href = "http://cxzx.hzhope.com/leaseAuth.html?type=lease";
    },
    toNewsDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id
        }
      });
    },
    toNewsList() {
      this.$router.push("/newsList");
    },
    toPark() {
      window.location.href = "http://www.zjlaishang.com/#/index";
    },
    toHouse() {
      //房屋出售
      this.$router.push({
        path: "/HouseList",
        query: {
          infoType: "1"
        }
      });
    },
    toRecruitmentList() {
      // 前往人才招聘
      this.$router.push({
        path: "/RecruitmentList",
        query: {
          infoType: "2"
        }
      });
    },
    toFindJobList() {
      // 前往求职
      this.$router.push({
        path: "/FindJobList",
        query: {
          infoType: "3"
        }
      });
    },
    toPromote() {
      //前往推广产品
      this.$router.push({
        path: "/PromoteList",
        query: {
          infoType: "4"
        }
      });
    },
    showPop() {
      this.show = true;
      this.$refs.tabbar.tabBarShow = false;
      this.jobShow = false;
    },
    closePop() {
      this.$refs.tabbar.tabBarShow = true;
    },
    getNews() {
      this.$request({
        method: "get",
        url: "/cms/news/",
        params: {
          page: this.page
        }
      }).then(res => {
        console.log(res);
        this.$store.commit("setDataLoading", false);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.img) {
              let objdata = `{
              "id":"${item.id}",
              "title":"${item.title}",
              "image":"${item.img}",
              "time":"${item.create_time.split(" ")[0]}"
              }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, "")));
              this.List.splice(3);
            } else {
              let objdata = `{
              "id":"${item.id}",
              "title":"${item.title}",
              "image": "${require("@/assets/images/noimg.jpg")}",
              "time":"${item.create_time.split(" ")[0]}"
              }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, "")));
              this.List.splice(3);
            }
          });
        } else if (!res.data || (!res.data.length && this.page == 0)) {
          //暂无内容
          this.hasData = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.tohome {
  position: fixed;
  right: 0.1rem;
  bottom: 2rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.07);
  background-image: url(~@/assets/images/home.svg);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 0.6rem;
  z-index: 1;
}
.inner {
  padding: 0.35rem 0.3rem 0 0.3rem;
}
.swiper {
  border-radius: 0.15rem;
  margin-bottom: 0.34rem;
  img {
    width: 100%;
    border-radius: 0.15rem;
  }
}
.tabbar {
  .iconfont:before {
    font-size: 0.6rem;
  }
}
.menu li {
  text-align: center;
  float: left;
  width: 25%;
  margin-bottom: 0.5rem;
  p {
    font-size: 0.36rem;
    margin-top: 0.22rem;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border-radius: 0.12rem;
    span {
      font-size: 0.75rem;
      color: #fff;
    }
    img {
      width: 100%;
    }
  }
}

.line {
  height: 0.23rem;
  background: #f5f5f5;
}
.menu ul {
  height: auto;
  overflow: hidden;
}
.section2 {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .t {
      font-size: 0.43rem;
    }
    .more {
      font-size: 0.38rem;
      color: #9e9e9e;
    }
  }
}
.section3 .emptxt{
  margin-bottom: 1rem;
  padding-top: .4rem !important;
}
.section3 ul {
  height: auto;
  overflow: hidden;
  padding-bottom: 1.8rem;
  li {
    height: auto;
    overflow: hidden;
    padding: 0.35rem 0;
    border-bottom: 1px solid #ebedf0;
    .img {
      float: right;
      width: 2.8rem;
      height: 1.9rem;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
    }
    .txt {
      margin-right: 3.5rem;
      height: 1.9rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-weight: normal;
        font-size: 0.38rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .sumary {
        color: #999;
        font-size: 0.34rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        span {
          margin-right: 0.3rem;
        }
      }
    }
  }
}
.agreePop {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem 0.35rem 0.3rem;
  .tit {
    font-size: 0.5rem;
    color: #222;
    margin-bottom: 0.7rem;
  }
  h2 {
    margin: 0.3rem 0;
    font-weight: normal;
    font-size: 0.42rem;
  }
  p {
    font-size: 0.36rem;
    color: #777;
    line-height: 0.65rem;
  }
  .body {
    overflow-y: auto;
    height: calc(100vh - 1.6rem);
  }
  .foot {
    height: 0.6rem;
  }
}
</style>